import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpShowError, InvocationResult, Loader} from 'hcl-lib';
import {environment} from 'projects/hcl-portal/src/environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Settings} from '../../interfaces/settings';

@Injectable({
  providedIn: 'root',
})
export class SettingService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  @HttpShowError()
  @Loader(true)
  getSettings(app: string): Observable<Settings> {
    const queryParams = new HttpParams()
      .set('app', app)

    return this.httpClient
      .get(environment.htiApiBaseUrl + '/settings', {params: queryParams})
      .pipe(
        map((res: any) => {
          return (res as InvocationResult).data;
        })
      );
  }

  @HttpShowError()
  updateSettings(settings: Settings) {
    return this.httpClient
      .put(environment.htiApiBaseUrl + '/settings/' + settings.id, settings)
      .pipe(map((_) => {
      }));
  }
}
